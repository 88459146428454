<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ $t(formTitle) }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item :label="$t('参数名称')" prop="configName">
        <a-input v-model="form.configName" :placeholder="$t('请输入')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('参数键名')" prop="configKey">
        <a-input v-model="form.configKey" :disabled="form.id?true:false" :placeholder="$t('请输入')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('参数类型')">
        <a-radio-group v-model="form.configType" button-style="solid" :disabled="form.id?true:false">
          <a-radio-button v-for="(d, index) in dict.type['sys_config_type']" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="form.configType==1" :label="$t('参数键值')" prop="configValue">
        <a-input v-model="form.configValue" :placeholder="$t('请输入')" />
      </a-form-model-item>

      <a-form-model-item v-if="form.configType==2" :label="$t('参数键值')" prop="configValue">
        <a-input v-model="form.configValue" :placeholder="$t('请输入')" type="textarea" allow-clear/>
      </a-form-model-item>

      <a-form-model-item v-else-if="form.configType==3" :label="$t('参数键值')" prop="configValue">
        <editor v-model="form.configValue" />
      </a-form-model-item>
      <!-- <a-form-model-item :label="$t('系统内置')" prop="configType">
        <a-radio-group v-model="form.configSystem" button-style="solid">
          <a-radio-button v-for="(d, index) in typeOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item> -->
      <a-form-model-item :label="$t('备注')" prop="remark">
        <a-input v-model="form.remark" :placeholder="$t('请输入')+$t('备注')" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { getConfig, addConfig, updateConfig } from '@/api/system/config'
import Editor from '@/components/Editor'
export default {
  name: 'CreateForm',
  dicts: ['sys_config_type'],
  props: {
    typeOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    Editor
  },
  data () {
    const validateTk = (rule, value, callback) => {
      if(this.form.configKey == 'ZK') {
        if(value>=1 && value<=10) {
          callback()
        } else {
          callback(new Error('只能输入1-10的折扣'))
        }
      } else {
        callback()
      }
      
    }
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: undefined,
        configName: undefined,
        configKey: undefined,
        configValue: undefined,
        configSystem: 'N',
        configType: '1', // 	参数类型 1-input,2-text,3-html
        remark: undefined
      },
      open: false,
      rules: {
        configName: [{ required: true, message: this.$t('参数名称') + this.$t('不能为空'), trigger: 'blur' }],
        configKey: [{ required: true, message: this.$t('参数键名') + this.$t('不能为空'), trigger: 'blur' }],
        configValue: [
          { required: true, message: this.$t('参数键值') + this.$t('不能为空'), trigger: 'blur' },
          { validator: validateTk, trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        id: undefined,
        configName: undefined,
        configKey: undefined,
        configValue: undefined,
        configSystem: 'N',
        configType: '1', // 	参数类型 1-input,2-text,3-html
        remark: undefined
      }
    },
     /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      this.open = true
      this.formTitle = '添加参数'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      const configId = row ? row.id : ids
      getConfig(configId).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改参数'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined) {
            updateConfig(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addConfig(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
